<template>
  <div class="intro-container">
    <div style="display: flex;width: 100%;flex-direction: row;">
      <div style="flex: 1;width: 100%;padding-right: 20px;box-sizing: border-box;">
        <span style="color: #D60034;font-weight: bold">What is IPsyE?</span>
        <br>
        IPsyE is an interactive learning system designed to help students learn psychological knowledge and theory
        through
        a combination of text-based courses and simulated counseling sessions. It aims to prepare students for the
        International Psychology Olympiad (IPsyO) exams. <br>
        <br>
        IPsyE comprises two main modules: IPsyO Academy and Virtual Therapy. The interactive format, which includes both
        text-based learning and simulated role-playing, adds an element of engagement and fun to the learning process.
        <br>
        <br>
        <span style="color: #D60034;font-weight: bold">IPsyO Academy</span>
        <br>
        Content: This module offers text-based courses that cover psychological theories, concepts, definitions, and
        characteristics. It is designed to help students prepare for the objective and short answer questions of the
        IPsyO
        exams.
        <br>
        Format: Students can read through educational materials and complete practice questions to reinforce their
        learning.
        <br>
        <br>
        <span style="color: #D60034;font-weight: bold">Virtual Therapy</span>
        <br>
        Content: This module provides simulated counseling sessions where students roleplay as a counselor assisting
        visitors with psychological problems. It aligns with the application questions in the IPsyO exams that involve
        simulated counseling scenarios.
        <br>
        Format: Each case consists of 10 sequential dialogues between the visitor and the student counselor. The visitor
        describes their situation through video clips, and students must choose appropriate responses from multiple
        choice
        options. Incorrect or omitted responses receive hints, while correct responses advance the case to the next
        dialogue.
      </div>
      <div v-if="accountPaid == false" style="width: 300px;display: flex;flex-direction: column;align-items: center;">
        <p>To access all content in IPsyE without any time limitations, you need to purchase the IPsyE Subscription.</p>
        <div class="btn-purchase" @click="doPay">Purchase IPsyE Subscription</div>
        <!-- <p style="margin-top: 30px;">IPsyE is part of the EAE Universal Pass portfolio. If you have purchased the EAE
          Universal Pass,you can
          directly verify your pass to access all IPsyE content.</p>
        <div class="btn-purchase" @click="doPay">Verify My EAE Universal Pass</div> -->
      </div>
    </div>
    <div style="width: 100%;height: 30px"></div>
  </div>
</template>

<script>
import {
  createApplication,
  checkApplicationNew
} from '../api/ipsye'
import {
  getUserId,
  isTestUser
} from '../utils/store'
export default {
  name: "Intro",
  date() {
    return {
      accountPaid: false
    }
  },
  mounted() {
    if (isTestUser()) {
      checkApplicationNew(getUserId()).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.accountPaid = true
        } else {
          this.accountPaid = false
        }
        this.$forceUpdate()
      })
    }
  },
  methods: {
    doPay() {
      let user_id = getUserId()
      createApplication(user_id).then((res) => {
        let result = res.data.data
        if (result.application_status == '无需缴费') {
          this.accountPaid = true
          this.$globalMessage.$emit('refreshApplication')
          this.$forceUpdate()
          return
        }
        this.$router.push({
          path: '/order',
          query: {
            application_id: result._id.$id
          }
        })
        this.showPayDialog = false
      })
    }
  }
}
</script>

<style scoped lang="less">
.btn-purchase {
  margin-top: 15px;
  width: 85%;
  padding: 6px 12px;
  text-align: center;
  background-color: #D60034;
  font-size: 16px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.intro-container {
  padding: 40px 60px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 90vh;
  background-color: #F8F8F8;
  font-size: 18px;
  font-weight: normal;
  color: #666666;
  word-break: keep-all;
  line-height: 32px;
}
</style>
